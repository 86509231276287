import { makeStyles } from '@material-ui/styles'
import {
  Typography as MuiTypography,
  styled,
  Grid as MuiGrid,
  CardMedia,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { ChevronRight } from 'react-feather'
import clsx from 'clsx'
import { Button, Sheet } from '@/components'
import { AlertMessage } from '@/app/components'
import { Text } from '@/components'

const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '20px 0px',
    '&:hover': {
      '& $subtitle': {
        color: theme.palette.blue?.main ?? '#426FFB',
      },
      '& $chevronRight': {
        color: theme.palette.blue?.main ?? '#426FFB',
      },
      cursor: 'pointer',
    },
    '&.coming-soon': {
      pointerEvents: 'none',
    },
  },
  cardImage: {
    height: '30px',
    objectFit: 'contain',
    '&.coming-soon': {
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
  },
  subtitle: {
    fontSize: 15,
    paddingLeft: theme.spacing(4),
    textTransform: 'capitalize',
    '&.coming-soon': {
      opacity: 0.5,
    },
  },
  rightContainer: {
    marginLeft: 'auto',
  },
  chevronRight: {
    color: '#B4B4B4',
    fontSize: 20,
  },
  comingSoonText: {
    display: 'inline',
    opacity: 0.5,
    verticalAlign: 'middle',
  },
}))

function RightCardSide({ classes, comingSoon }) {
  if (comingSoon) {
    return (
      <span className={classes.rightContainer}>
        <Typography variant="subtitle2" className={classes.comingSoonText}>
          Coming Soon!
        </Typography>
      </span>
    )
  }

  return (
    <span className={classes.rightContainer}>
      <ChevronRight className={classes.chevronRight} />
    </span>
  )
}

const BrandCard = ({
  make,
  onClick,
  index,
  arrayLength,
  id,
  includeName = true,
  comingSoon = false,
  backSoon = false,
  includeLastBorder = false,
  warningMessage = '',
}) => {
  const classes = useStyles()

  const maybeComingSoonClasses = comingSoon
    ? (cs) => clsx(cs, 'coming-soon')
    : (cs) => cs

  const handleClick = () => {
    if (!backSoon) {
      onClick(make)
    }
  }

  const isLastElement = () => {
    if ((index && arrayLength) || arrayLength === 1) {
      if (index === arrayLength - 1) return true
      return false
    }
    return false
  }

  return (
    <Grid container>
      <Button
        variant="click-wrapper"
        onClick={handleClick}
        data-testing-id={`brand-card-${make.name.toLowerCase()}`}
        id={`${id}-brand-card-${make.name.toLowerCase()}`}
      >
        <Grid
          container
          alignItems="center"
          className={`${maybeComingSoonClasses(classes.card)} !p-0`}
          style={{
            borderBottom:
              isLastElement() && !includeLastBorder
                ? null
                : '1px solid #E9E9E9',
          }}
        >
          <Grid
            item
            className="h-[70px] flex items-center justify-center"
            xs={2}
          >
            <CardMedia
              component="img"
              className={`${maybeComingSoonClasses(
                classes.cardImage,
              )} max-h-[60%] max-w-[80%] w-auto h-auto`}
              image={make.logo}
            />
          </Grid>
          {includeName && (
            <Grid item>
              <Typography
                variant="h2"
                className={maybeComingSoonClasses(classes.subtitle)}
              >
                {make.name}
              </Typography>
            </Grid>
          )}
          {backSoon && (
            <Sheet className="ml-4 bg-themed-blue-100">
              <Text variant="body3" className="mx-2 text-themed-blue-500">
                COMING SOON
              </Text>
            </Sheet>
          )}
          <RightCardSide
            classes={classes}
            comingSoon={comingSoon}
            backSoon={backSoon}
          />
        </Grid>
      </Button>
      {warningMessage && (
        <AlertMessage variant="warning">{warningMessage}</AlertMessage>
      )}
    </Grid>
  )
}

export default BrandCard
