import { ChangeEvent } from 'react'
import { IconButton } from '@mui/material'
import { Search as SearchIcon } from 'lucide-react'
import { TextField } from '@/components'
type Props = {
  onChange: (searchTerm: string) => void
}
const SearchBar = ({ onChange }: Props) => {
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className={'w-full flex flex-row gap-2 items-center p-2'}>
      <IconButton type="submit" aria-label="search">
        <SearchIcon size={26} color={'black'} />
      </IconButton>
      <TextField
        placeholder="Search"
        className={'w-full'}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
      />
    </div>
  )
}

export default SearchBar
