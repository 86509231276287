import { makeStyles } from '@material-ui/styles'
import {
  Typography as MuiTypography,
  Paper as MuiPaper,
  Grid as MuiGrid,
  styled,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import clsx from 'clsx'
import BrandCard from '../BrandCard'
import { Button, Icon, NavigationList } from '@/components'
import { remoteConfig } from '../../../firebase'
import { useNavigation } from '@/app/hooks'
import { BrandType } from '@/app/components/BrandNotFound/BrandNotFound'
import { ThermostatBrand, VehicleBrand } from '@/types/brand'
import VehicleSearch from '@/app/pages/connect-device/search/vehicle-search'
import { useState } from 'react'
import { Link } from '@/app/components/Link'
import useBasePath from '@/hooks/useBasePath'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import { useUrlSearchParam } from '@/hooks'

const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Paper = styled(MuiPaper)(spacing)

const useStyles = makeStyles(() => ({
  selectionArea: {
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    padding: '5px 20px 5px 20px',
    maxHeight: 500,
    overflowY: 'auto',
    width: '100%',
  },
  insetBottom: {
    boxShadow: 'inset 0 -20px 20px -20px rgb(0 0 0 / 20%)',
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'left',
    marginBottom: 30,
  },
}))

const BrandSelector = ({
  brandList,
  onButtonSelect,
  id,
  comingSoonBrandList = [],
  brandInWarningList = [],
  titleStyle = '',
  selectorAreaStyle = '',
  includeNames = true,
  title = 'Select Your Brand',
  includeInsetBottom = true,
  handleGoBack,
  brandType,
}: {
  brandList: VehicleBrand[]
  onButtonSelect: (brand: VehicleBrand | ThermostatBrand) => void
  id: string
  comingSoonBrandList?: VehicleBrand[]
  brandInWarningList?: VehicleBrand[]
  titleStyle?: string
  selectorAreaStyle?: string
  includeNames?: boolean
  title?: string
  includeInsetBottom?: boolean
  handleGoBack?: () => void
  brandType: BrandType
}) => {
  const navigation = useNavigation()
  const classes = useStyles()
  const warningMessage =
    remoteConfig?.getString('warning_thermostat_message') ??
    'Issues with this brand may affect connection.'
  const [brandListFiltered, setBrandListFiltered] = useState(brandList)
  const [comingSoonBrandListFiltered, setComingSoonBrandListFiltered] =
    useState(comingSoonBrandList)
  const [brandInWarningListFiltered, setBrandInWarningListFiltered] =
    useState(brandInWarningList)
  function handleSearch(value: string) {
    value = value.toLowerCase()
    setBrandListFiltered(
      brandList.filter((brand) => brand.name.toLowerCase().includes(value)),
    )
    setComingSoonBrandListFiltered(
      comingSoonBrandList.filter((brand) =>
        brand.name.toLowerCase().includes(value),
      ),
    )
    setBrandInWarningListFiltered(
      brandInWarningList.filter((brand) =>
        brand.name.toLowerCase().includes(value),
      ),
    )
  }

  const isAva = useIsAvaBasePath()
  const connectChargerLink = isAva
    ? '/ava/connect-device/charger/select'
    : '/connect-device/charger/select'
  const avaProgramId = remoteConfig?.getWhitelabelId('ava') ?? null
  const isOnboarding = useUrlSearchParam('onboarding')

  return (
    <Grid container>
      <Grid container item>
        {handleGoBack && (
          <Grid item>
            <Button
              id={`${id}-navigation-page-back`}
              variant="icon"
              className="mr-5 !p-0"
              onClick={handleGoBack}
            >
              <Icon name="ArrowLeft" size={24} color="grey-900" />
            </Button>
          </Grid>
        )}
        <Grid item>
          <Typography
            variant="h4"
            className={clsx(classes.mainTitle, titleStyle)}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      {brandType === 'vehicle' && (
        <div className={'w-full my-4'}>
          <Paper
            className={clsx(
              selectorAreaStyle,
              classes.selectionArea,
              includeInsetBottom && classes.insetBottom,
            )}
          >
            <VehicleSearch onChange={handleSearch} />
          </Paper>
        </div>
      )}
      <Paper
        className={clsx(
          selectorAreaStyle,
          classes.selectionArea,
          includeInsetBottom && classes.insetBottom,
        )}
      >
        {brandListFiltered.map((make, index, array) => (
          <BrandCard
            make={make}
            id={id}
            backSoon={make.backSoon}
            onClick={onButtonSelect}
            index={index}
            arrayLength={array.length}
            key={make.name}
            includeName={includeNames}
            includeLastBorder={true}
          />
        ))}
        {brandInWarningListFiltered.map((make, index, array) => (
          <BrandCard
            make={make}
            id={id}
            onClick={onButtonSelect}
            index={index}
            arrayLength={array.length}
            key={`warning${make.name}`}
            includeName={includeNames}
            warningMessage={warningMessage}
            includeLastBorder={comingSoonBrandList.length > 0}
          />
        ))}
        {comingSoonBrandListFiltered.map((make, index, array) => (
          <BrandCard
            comingSoon
            make={make}
            id={id}
            index={index}
            arrayLength={array.length}
            key={`comingSoon${make.name}`}
            includeName={includeNames}
            onClick={onButtonSelect}
          />
        ))}
        <NavigationList>
          <NavigationList.BasicItem
            key="i-dont-see-my-brand"
            id="i-dont-see-my-brand-nav-item"
            variant="character"
            character="?"
            label="I don't see my brand"
            onClick={() => navigation.push(`/connect-${brandType}/not-found`)}
            labelClasses="!text-base self-start flex self-center"
            characterClasses="self-center flex self-start m-auto"
          />
        </NavigationList>
      </Paper>
      <div className="flex justify-center w-full my-4">
        <Link to={connectChargerLink}>Connect my charger instead</Link>
      </div>
      {isAva && avaProgramId && isOnboarding && (
        <div className="flex justify-center w-full my-4">
          <Link to={`/ava/program-enrollment/${avaProgramId}/offer`}>
            Skip and enroll my address
          </Link>
        </div>
      )}
    </Grid>
  )
}

export default BrandSelector
